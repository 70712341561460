import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { KeyboardEvent } from 'react';
import { ADDED_FEE_AMOUNT, ADDED_FEE_CODE } from '../components/fees/constants';
import { CustomFeeItem } from '../components/fees/types';

export const encodeForDataTestId = (val: string) => {
    if (!val) return '';

    return val.trim().split(/\s+/).filter(Boolean).join('_');
};

export const handleAcceptOnlyNumbers = (event: KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    const isNumber = /^\d$/.test(key);
    const isDeleteKey = key === 'Delete' || event.keyCode === 46;
    const isBackspaceKey = key === 'Backspace' || event.keyCode === 8;
    const isCtrlKey = (event.ctrlKey || event.metaKey) && (key === 'c' || key === 'v');
    const isTabKey = key === 'Tab' || event.keyCode === 9;
    const isPoint = key === '.';

    if (!isNumber && !isPoint && !isDeleteKey && !isBackspaceKey && !isCtrlKey && !isTabKey) {
        event.preventDefault();
    }
};

export const createNewFee = (upfront: boolean, category: paymentServicesTypes.FeeCategory): CustomFeeItem => ({
    capIndicator: upfront,
    category: category,
    feeAmount: ADDED_FEE_AMOUNT,
    feeDescription: '',
    feeName: '',
    feeTypeId: ADDED_FEE_CODE,
    includedInCalc: true,
    isManualFee: true,
    isEdited: true,
    isDeleted: false
});

export const isEveryElementOnSameRow = (container: HTMLDivElement | null, selector: string): boolean => {
    if (!container) return false;

    const children = container.querySelectorAll(selector);
    const topBoundaries = Array.from(children).map((child) => child.getBoundingClientRect().top);

    // if all elements have the same top boundary, they are on the same row
    // otherwise, they are on different rows
    return new Set(topBoundaries).size === 1;
};
