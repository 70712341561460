import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '@makemydeal/dr-offer-redux';
import { offerReduxSelectors, deskingActionCreators } from '@makemydeal/dr-dash-store';
import { Button } from '@interstate/components/Button';
import { Box } from '@interstate/components/Box';
import { TaxResetDialog } from './TaxResetDialog';

type TaxesBottomBarProps = {
    setTaxOverrideFormEdited: (formEdited: boolean) => void;
};
export default function TaxesBottomBar(props: TaxesBottomBarProps) {
    const { setTaxOverrideFormEdited } = props;
    const isTaxResetDisabled = useSelector(offerReduxSelectors.isTaxResetDisabled);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dispatch = useDispatch();
    const [showReadyToResetDialog, setShowReadyToResetDialog] = useState(false);

    const resetTaxes = useCallback(() => {
        dispatch(actionCreators.resetTaxOverrides(offerType));
        dispatch(deskingActionCreators.hideTotalTaxRateSupersedeAlert());
        setTaxOverrideFormEdited(false);
        setShowReadyToResetDialog(false);
    }, [dispatch, offerType, setTaxOverrideFormEdited]);

    return (
        <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
            <Button
                data-testid="taxes-reset-all-btn"
                type="button"
                size="default"
                buttonStyle="secondary"
                disabled={isTaxResetDisabled}
                onClick={() => setShowReadyToResetDialog(true)}
            >
                Reset All
            </Button>
            {showReadyToResetDialog && (
                <TaxResetDialog
                    data-testid="reset-tax-dialog"
                    onHide={() => setShowReadyToResetDialog(false)}
                    onCancel={() => setShowReadyToResetDialog(false)}
                    onConfirm={resetTaxes}
                />
            )}
        </Box>
    );
}
