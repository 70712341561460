// externals
import { useSelector } from 'react-redux';

// components
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// libraries
import {
    accessoriesSelectors,
    compositeSelectors,
    offerReduxSelectors,
    offerSelectors,
    vehicleProtectionSelectors
} from '@makemydeal/dr-dash-store';
import type { DealHistory } from '@makemydeal/dr-dash-types';

// types
import type { LineItem } from '../../types/LineItemTypes';

// constants
import * as constants from '../../constants';

// feature toggles
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// utils
import {
    convertAccessoryToLineItem,
    convertDealerFeeToLineItem,
    convertGovernmentFeeToLineItem,
    convertVppProductToLineItem,
    getCappedFeeBreakdown,
    getStrAmountsWithoutSymbols,
    lineItemWithChildrenAdaptor,
    removeTaxesByName
} from '../utils/transform';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import DealSummaryItem from '../common/DealSummaryItem';
import LineItemWithChildren from '../common/LineItemWithChildren';

import { Grid } from '@interstate/components/Grid';
import { vehicleProtectionUtils } from '@makemydeal/dr-dash-store';
import { useMemo } from 'react';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// styles
import { expandibleAreaStyles, expandibleButtonStyles, labelStyles, secondaryValueStyles, valueStyles } from '../utils/styles';

export type AddsToCapCostProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const AddsToCapCost = ({ deal, useWrapper = true, hasDelta = false }: AddsToCapCostProps) => {
    const useDealRootSelector = createDealRootSelector(deal);

    // add to cap cost
    const addsToCapCostTotal = useDealRootSelector(compositeSelectors.getAddsToCapCostTotal);
    const addsToCapCostTotalAmount = useSelector(offerReduxSelectors.getAddsToCapCostTotal);

    // accessories
    const accessoriesTotal = useDealRootSelector(accessoriesSelectors.getCapAccessoriesTotal);
    const capAccessoriesTotal = useDealRootSelector(offerReduxSelectors.getCapitalizedAccessories);
    const itemizedAccessories = useDealRootSelector(accessoriesSelectors.getCapAccessoriesList).map(convertAccessoryToLineItem);
    const itemizedAccessoriesDisplay = lineItemWithChildrenAdaptor(getStrAmountsWithoutSymbols(itemizedAccessories), {
        key: 'label',
        label: 'label',
        value: 'value'
    });

    // taxes
    /* TODO: change addsToCapCostTotalAmount to addsToCapCostTotal
        & delete getCapitalizedTaxTotal reference when removing feature toggle
        getCapitalizedTaxTotal uses current prop that returns derived value while getCapitalizedTaxes dependent on PS and not implemented yet
    */
    //const capitalizedTaxTotal = useSelector(offerReduxSelectors.getCapitalizedTaxTotal);

    const capitalizedTaxTotal = useSelector(offerReduxSelectors.getCapitalizedTaxes);
    const hasManualTax = useSelector(offerReduxSelectors.hasManualTotalTax);
    const getFlatOrItemizedTaxes = useSelector(offerSelectors.getFlatOrItemizedTaxes);
    const shouldDisplayManualTax = hasManualTax && capitalizedTaxTotal !== 0;
    const getManualTax = useSelector(offerSelectors.getFlatTaxForDisplay);
    const getManualTaxDisplay = lineItemWithChildrenAdaptor(getManualTax, { key: 'taxCode', label: 'taxName', value: 'taxAmount' });
    const isTaxCapped = useSelector(offerReduxSelectors.isTaxCapped);
    // product ask to not display 'mut tax' and 'crt' is always upfront in the Adds to Cap Cost section
    const taxItemsToDisplay = isTaxCapped ? removeTaxesByName(getFlatOrItemizedTaxes, [constants.MUT_TAX, constants.CRT_TAX]) : [];

    const capitalizedTaxesDisplay = lineItemWithChildrenAdaptor(taxItemsToDisplay, {
        key: 'taxCode',
        label: 'taxName',
        value: 'taxAmount'
    });
    const itemizedTaxes = shouldDisplayManualTax ? getManualTaxDisplay : capitalizedTaxesDisplay;

    // fees
    const dealerFeeTotal = useDealRootSelector(offerSelectors.getCappedDealerFeesTotal);
    const itemizedDealerFees = useDealRootSelector(offerSelectors.getDealerFees);
    const dealerFeeChildren = useMemo(() => {
        const { cappedFees } = getCappedFeeBreakdown(itemizedDealerFees);

        return cappedFees.map(convertDealerFeeToLineItem);
    }, [itemizedDealerFees]);

    const capitalizedLenderFeesTotal = useSelector(offerReduxSelectors.getCapitalizedLenderFees);
    const itemizedLenderFees = useSelector(offerSelectors.getIncludedCappedLenderFees);
    const itemizedLenderFeesDisplay = lineItemWithChildrenAdaptor(itemizedLenderFees, {
        key: 'dealerFeeTypeCode',
        label: 'dealerFeeName',
        value: 'dealerFeeAmount'
    });

    const capitalizedGovFees = useSelector(offerReduxSelectors.getCapitalizedGovFees);
    const itemizedGovFees = useSelector(offerSelectors.getIncludedCappedGovernmentFees);
    const itemizedGovFeesDisplay = lineItemWithChildrenAdaptor(itemizedGovFees, {
        key: 'code',
        label: 'name',
        value: 'amount'
    });

    const capitalizedDealerFees = useSelector(offerReduxSelectors.getCapitalizedDealerFees);
    const dealerFeesItemized = useSelector(offerSelectors.getIncludedCappedDealerFees);
    const dealerFeesItemizedDisplay = lineItemWithChildrenAdaptor(dealerFeesItemized, {
        key: 'dealerFeeTypeCode',
        label: 'dealerFeeName',
        value: 'dealerFeeAmount'
    });

    const govermentFee = useDealRootSelector(offerReduxSelectors.getGovernmentFees);
    const { cappedFeeTotal, fee } = govermentFee;
    const govFeeChildren: LineItem[] = useMemo(() => {
        return fee.taxFee.filter((fee) => fee.capped && fee.amount > 0).map(convertGovernmentFeeToLineItem);
    }, [fee]);

    // protection products
    const protectionProductsTotal = useDealRootSelector(vehicleProtectionSelectors.getCapVppTotal);
    const capProtectionProductsTotal = useDealRootSelector(offerReduxSelectors.getCapitalizedProtectionProducts);
    const vppProducts = useDealRootSelector(vehicleProtectionSelectors.getVppProducts);
    const protectionProductsItems = useMemo(
        () =>
            vppProducts
                .filter(vehicleProtectionUtils.isVppProductSelected)
                .filter(vehicleProtectionUtils.isVppCapProduct)
                .map(convertVppProductToLineItem),
        [vppProducts]
    );
    const protectionProductsDisplay = lineItemWithChildrenAdaptor(getStrAmountsWithoutSymbols(protectionProductsItems), {
        key: 'label',
        label: 'label',
        value: 'value'
    });

    // prior loan balance
    const priorLoanBalance = useSelector(offerReduxSelectors.getPriorLoanBalance);

    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    if (isEnhancedDealDetailsEnabled) {
        return (
            <DealSummaryItem
                valueStyles={valueStyles}
                labelStyles={labelStyles}
                label={constants.ADDS_TO_CAP_COST}
                value={formatDollarsAndCents(addsToCapCostTotalAmount)}
            >
                <DealSummaryItem
                    valueStyles={secondaryValueStyles}
                    labelStyles={labelStyles}
                    expandibleButtonStyles={expandibleButtonStyles}
                    expandibleAreaStyles={expandibleAreaStyles}
                    label={constants.CAPITALIZED_TAXES}
                    value={formatDollarsAndCents(capitalizedTaxTotal)}
                >
                    {!!itemizedTaxes.length &&
                        itemizedTaxes.map((tax) => (
                            <LineItemWithChildren key={tax.key} label={tax.label} value={formatDollarsAndCents(tax.value)} />
                        ))}
                </DealSummaryItem>

                <DealSummaryItem
                    valueStyles={secondaryValueStyles}
                    labelStyles={labelStyles}
                    expandibleButtonStyles={expandibleButtonStyles}
                    expandibleAreaStyles={expandibleAreaStyles}
                    label={constants.LENDER_FEES}
                    value={formatDollarsAndCents(capitalizedLenderFeesTotal)}
                >
                    {!!itemizedLenderFeesDisplay.length &&
                        itemizedLenderFeesDisplay.map((fee) => (
                            <LineItemWithChildren key={fee.key} label={fee.label} value={formatDollarsAndCents(fee.value)} />
                        ))}
                </DealSummaryItem>

                <DealSummaryItem
                    valueStyles={secondaryValueStyles}
                    labelStyles={labelStyles}
                    expandibleButtonStyles={expandibleButtonStyles}
                    expandibleAreaStyles={expandibleAreaStyles}
                    label={constants.GOVERNMENT_FEES}
                    value={formatDollarsAndCents(capitalizedGovFees)}
                >
                    {!!itemizedGovFeesDisplay.length &&
                        itemizedGovFeesDisplay.map((fee) => (
                            <LineItemWithChildren key={fee.key} label={fee.label} value={formatDollarsAndCents(fee.value)} />
                        ))}
                </DealSummaryItem>

                <DealSummaryItem
                    valueStyles={secondaryValueStyles}
                    labelStyles={labelStyles}
                    expandibleButtonStyles={expandibleButtonStyles}
                    expandibleAreaStyles={expandibleAreaStyles}
                    label={constants.DEALER_FEES}
                    value={formatDollarsAndCents(capitalizedDealerFees)}
                >
                    {!!dealerFeesItemizedDisplay.length &&
                        dealerFeesItemizedDisplay.map((fee) => (
                            <LineItemWithChildren key={fee.key} label={fee.label} value={formatDollarsAndCents(fee.value)} />
                        ))}
                </DealSummaryItem>

                <DealSummaryItem
                    valueStyles={secondaryValueStyles}
                    labelStyles={labelStyles}
                    expandibleButtonStyles={expandibleButtonStyles}
                    expandibleAreaStyles={expandibleAreaStyles}
                    label={constants.PROTECTION_PRODUCTS}
                    value={formatDollarsAndCents(capProtectionProductsTotal)}
                >
                    {!!protectionProductsDisplay.length &&
                        protectionProductsDisplay.map((product) => (
                            <LineItemWithChildren
                                key={product.key}
                                label={product.label}
                                value={formatDollarsAndCents(product.value)}
                            />
                        ))}
                </DealSummaryItem>

                <DealSummaryItem
                    valueStyles={secondaryValueStyles}
                    labelStyles={labelStyles}
                    expandibleButtonStyles={expandibleButtonStyles}
                    expandibleAreaStyles={expandibleAreaStyles}
                    label={constants.ACCESSORIES}
                    value={formatDollarsAndCents(capAccessoriesTotal)}
                >
                    {!!itemizedAccessoriesDisplay.length &&
                        itemizedAccessoriesDisplay.map((accessory) => (
                            <LineItemWithChildren
                                key={accessory.key}
                                label={accessory.label}
                                value={formatDollarsAndCents(accessory.value)}
                            />
                        ))}
                </DealSummaryItem>

                {priorLoanBalance > 0 && (
                    <DealSummaryItem
                        valueStyles={secondaryValueStyles}
                        labelStyles={labelStyles}
                        expandibleButtonStyles={expandibleButtonStyles}
                        expandibleAreaStyles={expandibleAreaStyles}
                        label={constants.NEGATIVE_TRADE_EQUITY}
                        value={formatDollarsAndCents(priorLoanBalance)}
                    ></DealSummaryItem>
                )}
            </DealSummaryItem>
        );
    }

    return (
        <DealSummaryItemWrapper
            label={constants.ADDS_TO_CAP_COST}
            value={formatDollarsAndCents(addsToCapCostTotal)}
            useWrapper={useWrapper}
            hasDelta={hasDelta}
        >
            <Grid>
                <LineItemWithChildren
                    label={constants.ACCESSORIES}
                    value={formatDollarsAndCents(accessoriesTotal)}
                    childItems={itemizedAccessories}
                />
                <LineItemWithChildren
                    label={constants.DEALER}
                    value={formatDollarsAndCents(dealerFeeTotal)}
                    childItems={dealerFeeChildren}
                />
                <LineItemWithChildren
                    label={constants.GOV_FEES}
                    value={formatDollarsAndCents(cappedFeeTotal)}
                    childItems={govFeeChildren}
                />
                <LineItemWithChildren
                    label={constants.PROTECTION}
                    value={formatDollarsAndCents(protectionProductsTotal)}
                    childItems={protectionProductsItems}
                />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default AddsToCapCost;
