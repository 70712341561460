import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';

import { PriceInput } from '@interstate/components/PriceInput';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

import { roundValue } from '../utils';
import { MAX_DOWNPAYMENT_THRESHOLD } from '../../common/constants';

export interface DownPaymentInputFieldProps {
    downPaymentValue: number | null;
    rowIndex: number;
}

export const DownPaymentInputField = ({ downPaymentValue, rowIndex }: DownPaymentInputFieldProps) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const [downPaymentDisplay, setDownPaymentDisplay] = useState('');
    const [isFocused, setFocus] = useState(false);

    useEffect(() => {
        const roundedStringValue =
            downPaymentValue !== null && Number.isFinite(downPaymentValue) ? `${roundValue(String(downPaymentValue))}` : '';
        setDownPaymentDisplay(roundedStringValue);
    }, [downPaymentValue]);

    const handleChange = (e: InterstateOnChangeEvent<TextInputEventValue>) => setDownPaymentDisplay(e.target.value);

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
        setFocus(true);
        setTimeout(() => {
            const len = e.target.value.length;
            e.target.setSelectionRange(len, len);
        }, 100);
    };

    const handleBlur = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        const value = e.target.value;

        if (downPaymentValue === Number(value)) {
            const roundedValue = roundValue(value);
            setTimeout(() => {
                setDownPaymentDisplay(`${roundedValue ?? ''}`);
                setFocus(false);
            });
            return;
        }

        logNewRelicPageAction('MV:PaymentGrid - Change cash down', {
            mvOfferType: offerType,
            mvFieldEdited: 'mv:payment:grid:cash-down',
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updatePaymentGridCashdown(offerType, rowIndex, Number(value)));
        setFocus(false);
    };

    return (
        <PriceInput
            autoInsertCommas
            allowNegative={false}
            id={'grid-cashdown-input'}
            data-testid={'grid-cashdown-input'}
            maxValue={MAX_DOWNPAYMENT_THRESHOLD}
            allowDecimal={true}
            decimalMaxLength={2}
            name={'cashdown-input'}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={isFocused ? String(downPaymentValue ?? '') : downPaymentDisplay}
        />
    );
};
