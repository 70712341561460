// hooks
import { useSelector } from 'react-redux';

// types
import { DealHistory } from '@makemydeal/dr-dash-types';
import { FC, useMemo } from 'react';
// selectors
import {
    accessoriesSelectors,
    compositeSelectors,
    offerReduxSelectors,
    offerSelectors,
    vehicleProtectionSelectors,
    vehicleProtectionUtils
} from '@makemydeal/dr-dash-store';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// components
import { LineItemCategory } from '@makemydeal/dr-platform-types';
import DealSummaryItem from '../common/DealSummaryItem';
import LineItemWithChildren from '../common/LineItemWithChildren';
import {
    convertAccessoryToLineItem,
    convertVppProductToLineItem,
    lineItemWithChildrenAdaptor,
    removeTaxesByName
} from '../utils/transform';

// styles
import { expandibleAreaStyles, expandibleButtonStyles, labelStyles, secondaryValueStyles, valueStyles } from '../utils/styles';

type DueAtSigningProps = {
    deal?: DealHistory;
};

const DueAtSigning: FC<DueAtSigningProps> = ({ deal }) => {
    const useDealRootSelector = createDealRootSelector(deal);

    const totalAmountDue = useDealRootSelector(offerReduxSelectors.getDueAtSigningFromDueInAdvance);
    const formattedTotalAmountDue = formatDollarsAndCents(totalAmountDue);

    const monthlyPayment = useDealRootSelector(offerSelectors.getMonthlyPayment);

    // Taxes
    const upfrontTaxTotal = useSelector(offerReduxSelectors.getUpfrontTaxTotal);
    const hasManualTax = useSelector(offerReduxSelectors.hasManualTotalTax);
    const getFlatOrItemizedTaxes = useSelector(offerSelectors.getFlatOrItemizedTaxes);
    const shouldDisplayManualTax = hasManualTax && upfrontTaxTotal !== 0;
    const getManualTax = useSelector(offerSelectors.getFlatTaxForDisplay);
    const getManualTaxDisplay = lineItemWithChildrenAdaptor(getManualTax, { key: 'taxCode', label: 'taxName', value: 'taxAmount' });
    const isTaxCapped = useSelector(offerReduxSelectors.isTaxCapped);
    const taxItemsToDisplay = !isTaxCapped ? removeTaxesByName(getFlatOrItemizedTaxes, [constants.MUT_TAX]) : [];
    const upfrontTaxesDisplay = lineItemWithChildrenAdaptor(taxItemsToDisplay, {
        key: 'taxCode',
        label: 'taxName',
        value: 'taxAmount'
    });
    const itemizedTaxes = shouldDisplayManualTax ? getManualTaxDisplay : upfrontTaxesDisplay;

    // Lender fees
    const upFrontLenderFeesTotal = useSelector(offerReduxSelectors.getDiaLenderFees);
    const itemizedLenderFees = useSelector(compositeSelectors.getLenderFeesUpFront);

    // Gov fees
    const upfrontGovFeesTotal = useDealRootSelector(offerReduxSelectors.getDiaGovFees);
    const upfrontGovFees = useDealRootSelector(compositeSelectors.getGovernmentFeesUpFront).filter((fee) => fee.amount > 0);

    // Dealer fees
    const upfrontDealerFeesTotal = useDealRootSelector(offerReduxSelectors.getDiaDealerFees);
    const upfrontDealerFees = useDealRootSelector(compositeSelectors.getDealerFeesUpfront).filter((fee) => fee.dealerFeeAmount > 0);

    const secDeposit = useDealRootSelector(offerSelectors.getSecurityDepositOverrideWithFallback);

    const selectedCapCostReduction = useDealRootSelector(compositeSelectors.getCapCostReduction);

    const netCashDown = useDealRootSelector(offerReduxSelectors.getNetCashDown);
    const reductionRebate = useDealRootSelector(offerReduxSelectors.getCapReductionRebate);
    const reductionTradeIn = useDealRootSelector(offerReduxSelectors.getCapReductionTradeIn);

    const accessoriesTotal = useDealRootSelector(offerReduxSelectors.getDiaAccessories);
    const itemizedAccessories = useDealRootSelector(accessoriesSelectors.getUpfrontAccessoriesList).map(convertAccessoryToLineItem);

    const protectionProductsTotal = useDealRootSelector(offerReduxSelectors.getDiaProtectionProducts);
    const vppProducts = useDealRootSelector(vehicleProtectionSelectors.getVppProducts);
    const protectionProductsItems: LineItemCategory[] = useMemo(
        () =>
            vppProducts
                .filter(vehicleProtectionUtils.isVppProductSelected)
                .filter(vehicleProtectionUtils.isVppUpfrontProduct)
                .map(convertVppProductToLineItem),
        [vppProducts]
    );
    return (
        <DealSummaryItem
            valueStyles={valueStyles}
            labelStyles={labelStyles}
            label={constants.DUE_AT_SIGNING}
            value={formattedTotalAmountDue}
        >
            <LineItemWithChildren
                containerStyles={{ py: 1 }}
                label={constants.FIRST_ST_MONTHLY_PAYMENT}
                value={formatDollarsAndCents(monthlyPayment)}
            />
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.UPFRONT_TAXES}
                value={formatDollarsAndCents(upfrontTaxTotal)}
            >
                {!!itemizedTaxes.length &&
                    itemizedTaxes.map((tax) => (
                        <LineItemWithChildren key={tax.key} label={tax.label} value={formatDollarsAndCents(tax.value)} />
                    ))}
            </DealSummaryItem>
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.LENDER_FEES}
                value={formatDollarsAndCents(upFrontLenderFeesTotal)}
            >
                {!!itemizedLenderFees.length &&
                    itemizedLenderFees.map((lender) => (
                        <LineItemWithChildren
                            key={lender.dealerFeeName}
                            label={lender.dealerFeeName}
                            value={formatDollarsAndCents(lender.dealerFeeAmount)}
                        />
                    ))}
            </DealSummaryItem>
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.GOVERNMENT_FEES}
                value={formatDollarsAndCents(upfrontGovFeesTotal)}
            >
                {!!upfrontGovFees.length &&
                    upfrontGovFees.map((govFees) => (
                        <LineItemWithChildren
                            key={govFees.name}
                            label={govFees.name}
                            value={formatDollarsAndCents(govFees.amount)}
                        />
                    ))}
            </DealSummaryItem>
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.DEALER_FEES}
                value={formatDollarsAndCents(upfrontDealerFeesTotal)}
            >
                {!!upfrontDealerFees.length &&
                    upfrontDealerFees.map((dealer) => (
                        <LineItemWithChildren
                            key={dealer.dealerFeeName}
                            label={dealer.dealerFeeName}
                            value={formatDollarsAndCents(dealer.dealerFeeAmount)}
                        />
                    ))}
            </DealSummaryItem>
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.PROTECTION_PRODUCTS}
                value={formatDollarsAndCents(protectionProductsTotal)}
            >
                {!!protectionProductsItems.length &&
                    protectionProductsItems.map((product) => (
                        <LineItemWithChildren key={product.label} label={product.label} value={product.value} />
                    ))}
            </DealSummaryItem>
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.ACCESSORIES}
                value={formatDollarsAndCents(accessoriesTotal)}
            >
                {!!itemizedAccessories.length &&
                    itemizedAccessories.map((acc) => <LineItemWithChildren key={acc.label} label={acc.label} value={acc.value} />)}
            </DealSummaryItem>
            <LineItemWithChildren
                containerStyles={{ py: 1 }}
                label={constants.SECURITY_DEPOSIT}
                value={formatDollarsAndCents(secDeposit)}
            />
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.CAP_COST_REDUCTION}
                value={formatDollarsAndCents(selectedCapCostReduction)}
            >
                <LineItemWithChildren label={constants.CASH_FROM_CUSTOMER} value={formatDollarsAndCents(netCashDown)} />
                <LineItemWithChildren label={constants.REBATES} value={formatDollarsAndCents(reductionRebate)} />
                <LineItemWithChildren label={constants.TRADE_IN} value={formatDollarsAndCents(reductionTradeIn)} />
            </DealSummaryItem>
        </DealSummaryItem>
    );
};

export default DueAtSigning;
